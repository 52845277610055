import React, { useState } from "react";
import { FaWhatsapp } from 'react-icons/fa';
import '../styles/whatsapp.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from '../img/logo.jpeg'
const IconWhatsapp = () => {
  // const [mensajePersonalizado, setMensajePersonalizado] = useState("");

  return (
    <>
      <FloatingWhatsApp
      
        avatar={logo}
        accountName="Mooon"
        notification={true}
        statusMessage="¡Hola! ¿En qué puedo ayudarte?"
        notificationDelay={10}
        // notificationLoop={3000}
        placeholder="escribe un mensaje..."
        chatMessage="En que podemos ayudarte?"
        phoneNumber="+595975885166"
        // onMessageChange={(message) => setMensajePersonalizado(message)}
      />
    </>
  );
};

export default IconWhatsapp;
