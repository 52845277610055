import React, { Suspense, lazy, useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cookies from 'universal-cookie';
import IconWhatsapp from './components/IconWhatsapp';
import ScrollArrow from './components/ScrollArrow';
import './styles/styles.css'

//* LISTO
const Header = lazy(() => import('./components/Header'))
const NotFound = lazy(() => import('./templates/NotFound'))
const Inicio = lazy(() => import('./templates/Inicio'));
const Footer = lazy(() => import('./components/Footer'));
const Contactos = lazy(() => import('./templates/contactos'))
const CuadrosList = lazy(() => import('./templates/Lista_de_cuadros_para_la_compra'))
const CuadroDetalle = lazy(() => import('./templates/Detalle_del_cuadro_a_comprar'))
const MiPedido = lazy(() => import('./templates/Pedido_del_usuario'))
const Cajas_Personalizadas = lazy(() => import('./templates/Cajas_Personalizadas'))
const Productos = lazy(() => import('./templates/Productos'))
const SobreNosotros = lazy(() => import('./templates/SobreNosotros'))
const IniciarSesion = lazy(() => import('./templates/Registrarse_o_iniciar_sesion'))
const Loading = lazy(() => import('./components/Loading'))
const MiCarrito = lazy(() => import('./templates/El_carrito_del_usuario'))

//! faltan chequear
const MiCuenta = lazy(() => import('./templates/Mi_perfil'))
const ChangePasswordByUser = lazy(() => import('./templates/ChangePasswordByUser')) //! falta revisar mejor
const Politics_and_privacy = lazy(() => import('./templates/politics_and_privacy'))
const ProductosClientes_Admin = lazy(() => import('./templates/ProductosClientes_Admin'))
const Personalizar_Cuadro = lazy(() => import('./templates/Personalizar_Cuadro'))


const cookies = new Cookies();

function App() {
  const tokenUser = cookies.get('tokenUser')
  const tokenAdmin = cookies.get('tokenAdmin')
  const [IdbyUser, setIdbyUser] = useState(null);
  const [products, setLocalProducts] = useState([]);
  const [testimonials, setTestimonys] = useState([])
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState('')

  const getTestimonys = async () => {
    try {
      // console.log("obtenemos testimonios")
      const response = await axios.post(`https://moon-2023-web-backend.vercel.app/api/getTestimonys`)
      setTestimonys(response.data.testimonios)

    } catch (error) {
      console.error(`error: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const consumeUsers = async () => {
    try {
      const response = await axios.post(`https://moon-2023-web-backend.vercel.app/api/obtenerUsuarios`, {idUser:IdbyUser})
      setUser(response.data)
    } catch (error) {
      console.error(`error: ${error}`)
    }
  }

  const obtenerIdByUser = async () => {
    try {
      const response = await axios.post(`https://moon-2023-web-backend.vercel.app/api/getIdByUser`, { tokenUser: tokenUser })
      setIdbyUser(response.data)
      // console.log("response data", response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {

    if (!tokenUser && !tokenAdmin) {
      getTestimonys()
    } else if (tokenUser) {
      obtenerIdByUser()
      // console.log("IdbyUser",IdbyUser, loading)
    } else if (tokenAdmin) {
      consumeAllProducts();
    }
  }, [tokenUser, tokenAdmin, IdbyUser]);

  useEffect(() => {
    // console.log("ID USER", IdbyUser)
    if (IdbyUser) {
      consumeUsers()
      getProductByUserLoggedAndTestimony()
    }
  }, [IdbyUser])

  const consumeAllProducts = async () => {
    try {
      const response = await axios.post(`https://moon-2023-web-backend.vercel.app/api/getProducts-admin`);
      const responseTestimonios = await axios.post(`https://moon-2023-web-backend.vercel.app/api/getTestimonys`)
      setTestimonys(responseTestimonios.data.testimonios)

      if (response.data.message) {
        setMessage(response.data.message)
      } else if (response.data) {
        const products = response.data.detailProducts
        setLocalProducts(products);
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  const getProductByUserLoggedAndTestimony = async () => {
    try {
      const baseUrl = `https://moon-2023-web-backend.vercel.app/api`;

      const responseTestimonios = await axios.post(`${baseUrl}/getTestimonys`);
      setTestimonys(responseTestimonios.data.testimonios);
      // console.log("125", IdbyUser)
      const responseProducts = await axios.post(`${baseUrl}/getProductsByUser`, { idUser: IdbyUser });
      // console.log("responseProducts", responseProducts)
      if (responseProducts.data.message) {
        setMessage(responseProducts.data.message)
      } else if (responseProducts.data) {
        const products = responseProducts.data.detailProducts
        setLocalProducts(products);
        // console.log(products)
      }
      // console.log("PARAMOS 128")
      // setLoading(false)
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      // console.log("PARAMOS 133")
      setLoading(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Header tokenUser={tokenUser} tokenAdmin={tokenAdmin} products={products} user={user} setLoading={setLoading} />
        <Routes>
          <Route index element={<Inicio IdbyUser={IdbyUser} tokenUser={tokenUser} tokenAdmin={tokenAdmin} testimonials={testimonials} />} />
          <Route path='contactos' element={<Contactos />} />
          <Route path='producto_cuadros' element={<CuadrosList IdbyUser={IdbyUser} tokenUser={tokenUser} tokenAdmin={tokenAdmin} />} />
          <Route path='cuadro/:titulo' element={<CuadroDetalle IdbyUser={IdbyUser} tokenUser={tokenUser} tokenAdmin={tokenAdmin} setLoading={setLoading} />} />
          <Route path='mi_carrito/:titulo' element={<MiPedido IdbyUser={IdbyUser} tokenUser={tokenUser} setLoading={setLoading} />} />
          {/* <Route path='cajas_Personalizadas' element={<Cajas_Personalizadas IdbyUser={IdbyUser} tokenUser={tokenUser} tokenAdmin={tokenAdmin} />} /> */}
          {/* <Route path='productos' element={<Productos IdbyUser={IdbyUser} tokenUser={tokenUser} tokenAdmin={tokenAdmin} />} /> */}
          <Route path='sobre_nosotros' element={<SobreNosotros />} />
          <Route path='productos_clientes' element={<ProductosClientes_Admin message={message} tokenAdmin={tokenAdmin} products={products} setLoading={setLoading} />} />
          <Route path='mi_carrito' element={<MiCarrito message={message} tokenAdmin={tokenAdmin} products={products} IdbyUser={IdbyUser} tokenUser={tokenUser} setLoading={setLoading} />} />
          <Route path='mi_cuenta' element={<MiCuenta message={message} products={products} IdbyUser={IdbyUser} tokenUser={tokenUser} testimonials={testimonials} user={user} setLoading={setLoading} />} />
          <Route path='iniciar_sesion' element={<IniciarSesion tokenAdmin={tokenAdmin} setLoading={setLoading} IdbyUser={IdbyUser} tokenUser={tokenUser} />} />
          <Route path='politics_and_privacy' element={<Politics_and_privacy />} />
          <Route path='new_password/:IdCliente' element={<ChangePasswordByUser setLoading={setLoading} />} />
          <Route path='personalizar_mi_cuadro' element={<Personalizar_Cuadro />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <IconWhatsapp />
        <ScrollArrow />
        <Footer tokenUser={tokenUser} tokenAdmin={tokenAdmin} />
      </Suspense>
    </Router>
  );
}
export default App;
